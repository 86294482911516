import React from 'react'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import YouTube from 'react-youtube';
import './rich-text.scss'

const RichText = props => {
  const { jsonInfo, className, references, ...params } = props;
  
  const optionDescription = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
        let contentfulId = node.data.target.sys.id
        let assetData = references?.find(el => el.contentful_id === contentfulId) || null

        if (assetData !== null && assetData.file?.contentType.toLowerCase().includes("image")) {
          return <img
            className="f-rich-img"
            src={assetData.file?.url}
            alt={assetData.title || ""}
          />
        } else {
          return null
        }
      },
      [BLOCKS.PARAGRAPH]: (node, next) => next && next !== '' ? <p>{next}</p> : '',
      [INLINES.HYPERLINK]: (node, next) => {
        let urlAsset = node.data.uri || ""
        if (urlAsset.includes("https://") && (urlAsset.includes("youtube") || urlAsset.includes("youtu.be"))) {
          const videoID = node.data.uri !== null ? node.data.uri.split('/')[node.data.uri.split('/').length - 1].replace("watch?v=", "") : null;
          return <YouTube
            videoId={videoID}
            opts={{ width: "100%" }}
            containerClassName="f-news-main__video"
          />
        } else {
          return <a className="f-rich-link" href={urlAsset} rel="noopener noreferrer" target="_blank">{next}</a>
        }
      }
    },
  }

  return (
    <div className={"rich-text-container " + (className ? className : '')} {...params}>
      {
        documentToReactComponents(jsonInfo, props.options ? { ...optionDescription.renderNode, ...props.options.renderNode } : optionDescription)
      }
    </div >
  )
}

export default RichText;
